import { useEffect } from 'react';
import WOW from 'wowjs';

const initWow = () => {
  const wow = new WOW.WOW({
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: false,
    live: true,
    scrollContainer: null,
  });
  wow.init();
};

export default () => {
  useEffect(() => {
    initWow();
  }, []);

  return {};
};
