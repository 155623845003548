import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { MDBRow } from 'mdbreact';
import useFirebaseData from '../../utils/useFirebaseData';
import {
  IconWrapper, ItemLabel, ItemValue, StatItem,
} from './styled';

export { Background as FactsBackground } from './styled';

const allFacts = [
  {
    id: 'stayConnected',
    icon: ['fas', 'cloud'],
    label: 'Connected Device',
    linkAttr: {
      href: '/storybook/?path=/story/active-info',
      title: 'Stay Connected',
    },
    getValue: (homepageStats) => homepageStats.stayConnected && homepageStats.stayConnected.count,
  },
  {
    id: 'activeStocks',
    icon: ['fas', 'chart-line'],
    label: '$ worth stocks in NASDAQ',
    linkAttr: {
      href: '/storybook/?path=/story/activestocks',
      title: 'Active Stocks',
    },
    getValue: (homepageStats) => homepageStats.livePortfolio
      && homepageStats.livePortfolio.marketCost,
  },
  {
    id: 'comments',
    icon: ['far', 'comments'],
    label: 'Comments',
    getValue: (homepageStats) => homepageStats.commentsCount,
  },
  {
    id: 'visitors',
    icon: ['fas', 'users'],
    label: 'Visitors',
    getValue: (homepageStats) => homepageStats.visitorsCount,
  },
];

// eslint-disable-next-line react/prop-types
export default ({ homepageStats = useFirebaseData('public/homepageStats') }) => (
  <MDBRow>
    {allFacts.map((fact) => (
      <StatItem key={fact.id} xs="12" sm="6" md="3">
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <a {...fact.linkAttr}>
          <IconWrapper>
            <FontAwesomeIcon icon={fact.icon} size="3x" />
          </IconWrapper>
          <ItemValue>
            { fact.getValue(homepageStats) || '-' }
          </ItemValue>
          <ItemLabel>{ fact.label }</ItemLabel>
        </a>
      </StatItem>
    ))}
  </MDBRow>
);
