import React from 'react';
import { MDBRow } from 'mdbreact';
import {
  BackgroundColors, ColCopyright, ColYear, Description, Version,
} from './styled';

export default () => {
  const {
    hover, footer, v1, v2, v3,
  } = BackgroundColors;
  return (
    <>
      <MDBRow>
        <ColYear background={{ default: v1, hover }} size="3">
          <Version>v1 @ 2011</Version>
          <Description>HTML + PHP + MySQL</Description>
        </ColYear>
        <ColYear background={{ default: v2, hover }} size="3">
          <Version>v2 @ 2015</Version>
          <Description>HTML5 + Bootstrap + Node.JS + MongoDB</Description>
        </ColYear>
        <ColYear background={{ default: v3, hover }} size="3">
          <Version>v3 @ 2018</Version>
          <Description>ReactJS + Node.JS + MongoDB + Firebase</Description>
        </ColYear>
        <ColYear background={{ default: footer }} current="true" size="3">
          <Version>v4 @ 2020</Version>
          <Description>React Hooks + Firebase</Description>
        </ColYear>
      </MDBRow>
      <MDBRow>
        <ColCopyright>
          © Ameya Naik (2011 - 2020)
        </ColCopyright>
      </MDBRow>
    </>
  );
};
