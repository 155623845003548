import React, { useEffect } from 'react';
import ActiveInfo from './components/ActiveInfo';
import ContactMe from './components/ContactMe';
import Experience from './components/Experience';
import Footer from './components/Footer';
import MyCarousel from './components/MyCarousel';
import Projects from './components/Projects';
import Section from './components/Section';
import InterestingFacts, { FactsBackground } from './components/InterestingFacts';

function App() {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    document.title = 'Ameya Naik | Software Engineer @ Twitter';
  }, []);

  return (
    <>
      <MyCarousel />
      <Section title="Active Info v3">
        <ActiveInfo />
      </Section>
      <Section title="Experience">
        <Experience />
      </Section>
      <Section icon={['fas', 'rocket']} title="Projects">
        <Projects />
      </Section>
      <FactsBackground>
        <Section icon={['far', 'clock']} title="Interesting Facts">
          <InterestingFacts />
        </Section>
      </FactsBackground>
      <Section icon={['fas', 'tag']} title="Wanna get in touch with me?">
        <ContactMe />
      </Section>
      <Footer />
    </>
  );
}

export default App;
