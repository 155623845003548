import styled from 'styled-components';
import { MDBCol, MDBContainer } from 'mdbreact';

export const Container = styled(MDBContainer)`
  font-size: 18px;
  padding: 20px;
`;

export const DividerCol = styled(MDBCol)`
  width: 100%;
  text-align: center;
  color: #cccccc;

  &:before,
  &:after {
    bottom: 6px;
    content: "_________________";
    color: #e6e8ea;
    position: relative;
  }

  &:before {
    right: 10px;
  }

  &:after {
    left: 10px;
  }
`;

export const TitleCol = styled(MDBCol)`
  color: #0eb493;
  font-size: 28px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase; 
  width: 100%;
`;
