import { useContext, useEffect, useState } from 'react';
import { FirebaseContext } from './firebase';

export default (databasePath) => {
  const { database } = useContext(FirebaseContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    database.ref(databasePath).on('value', (snapshot) => {
      setData(snapshot.val());
    });
  }, [databasePath, database]);

  return data;
};
