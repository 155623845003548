import { FormGroup } from 'react-bootstrap';
import styled from 'styled-components';

export const ContactInfoItem = styled.div`
  a {
    color: rgba(14, 180, 147, 0.5);
    display: block;
    margin-bottom: 10px;
  }

  a:hover {
    color: #0eb493;
  }

  a .text {
    padding: 10px;
  }
`;

export const CustomFormGroup = styled(FormGroup)`
  display: flex;
  margin-bottom: 5px;
  vertical-align: middle;
`;

export const ErrorText = styled.span`
  color: red;
  font-weight: 700;
  font-size: small;
`;

export const Title = styled.span`
  color: #0eb493;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
`;
