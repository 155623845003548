import { MDBCol } from 'mdbreact';
import styled from 'styled-components';

export const BackgroundColors = {
  footer: '#b40e0ec7',
  hover: '#fd662dfa',
  v1: '#39CCCC',
  v2: '#3D9970',
  v3: 'teal',
};

export const ColCopyright = styled(MDBCol)`
  background: ${BackgroundColors.footer};
  color: white;
  height: 30px;
  text-align: center;
`;

export const Description = styled.span`
  display: none;
  font-weight: bold;
  margin: auto;
  text-align: center;
`;

export const Version = styled.span`
  font-size: 20px;
  margin: auto;
`;

export const ColYear = styled(MDBCol)`
  background-color: ${(props) => props.background.default};
  color: white;
  display: flex;
  flex-direction: column;
  height: 100px;
  text-align: center;
  transition: background-color 1s ease;

  ${(props) => (props.current ? '&' : '&:hover')} {
    background-color: ${(props) => props.background.hover};
    transition: background-color 0.2s ease;
    
    ${Description} {
      display: unset;
    }

    ${Version} {
      font-size: 16px;
      height: 10px;
    }
  }
`;
