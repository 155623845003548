import React from 'react';
import styled from 'styled-components';
import { MDBCol, MDBRow } from 'mdbreact';
import ProjectDetails from './ProjectDetails';
import useFirebaseData from '../../utils/useFirebaseData';

import Info from '../core/Info';

const MenuHint = styled.p`
  color: #0eb493;
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
`;

export const projectGroups = {
  Category: ['Highlights', 'All', 'Academic', 'Applications', 'Robotics', 'Web', 'Other'],
  'Programming Language:': ['PHP', 'HTML5', 'jQuery', 'Node.js', 'Python', 'C', 'Java', 'Shell'],
  'Database:': ['MongoDB', 'MySQL'],
  'Hardware:': ['Arduino', 'Raspberry Pi'],
};

// eslint-disable-next-line react/prop-types
export default ({ projects = useFirebaseData('public/projects') }) => (
  <>
    <MDBRow>
      <MDBCol xs="12">
        <Info>
          No doubt there are enormous apps, websites, tools out there online,
          but it is almost impossible to find a one to meet exactly ones needs.
        </Info>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xs="12">
        <Info>
          Having a keen interest in coding and always look forward to laying
          my hands on a new programming language, I decided to go further
          and develop applications as per my needs. Here are few of them,
        </Info>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xs="12">
        <MenuHint>Use following menu to explore about projects</MenuHint>
      </MDBCol>
    </MDBRow>
    <ProjectDetails projectGroups={projectGroups} projects={projects} />
  </>
);
