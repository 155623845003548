import React, { createContext } from 'react';
import * as firebase from 'firebase';

const FirebaseContext = createContext({});
export { FirebaseContext };

const firebaseConfigs = {
  'ameya-naik-com': {
    appId: '1:993874107692:web:55ca03c00d485e2695e089',
    apiKey: 'AIzaSyD-xTs_FdAUP4c6CDyAVjwSp_a8r1VLt1g',
    authDomain: 'ameya-naik-com.firebaseapp.com',
    databaseURL: 'https://ameya-naik-com.firebaseio.com',
    messagingSenderId: '993874107692',
    measurementId: 'G-64V7Z9LCQL',
    projectId: 'ameya-naik-com',
    storageBucket: 'ameya-naik-com.appspot.com',
  },
  fxRates: {
    apiKey: 'AIzaSyBWoJYsD0MJLC8UXvllxJ7k2zgpicUfGRc',
    appId: '1:342156255470:web:d03f0227fccec0ec6813ff',
    authDomain: 'fx-rates-ameyanaik.firebaseapp.com',
    databaseURL: 'https://fx-rates-ameyanaik.firebaseio.com',
    measurementId: 'G-0GEQCRE1P2',
    projectId: 'fx-rates-ameyanaik',
  },
};

// Courtesy: https://medium.com/@joeponzio/the-right-way-to-add-firebase-to-your-react-project-using-react-hooks-a32db20bf1a0
// eslint-disable-next-line react/prop-types
export default ({ children }) => {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfigs['ameya-naik-com']);
    firebase.initializeApp(firebaseConfigs.fxRates, 'fxRates');
    firebase.analytics();
    firebase.app('fxRates').analytics();
  }
  const databaseRef = {
    database: firebase.database(),
    fxRates: firebase.app('fxRates').database(),
  };
  return (
    <FirebaseContext.Provider value={databaseRef}>
      { children }
    </FirebaseContext.Provider>
  );
};
