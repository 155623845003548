import Moment from 'react-moment';
import styled from 'styled-components';
import { MDBCol } from 'mdbreact';
import React from 'react';
import Year from '../core/Year';

const ProjectItem = styled(MDBCol)`
  font-size: 14px;
  font-weight: 600;
  padding: 8px;

  height: 200px;

  float: left;
  position: relative;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HoverOverlay = styled.div`
  background-color: rgba(14, 180, 147, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 450ms ease-out 0s;
  -moz-transition: all 450ms ease-out 0s;
  -o-transition: all 450ms ease-out 0s;
  transition: all 450ms ease-out 0s;
  -webkit-transform: rotateY(180deg) scale(0.5, 0.5);
  -moz-transform: rotateY(180deg) scale(0.5, 0.5);
  -ms-transform: rotateY(180deg) scale(0.5, 0.5);
  -o-transform: rotateY(180deg) scale(0.5, 0.5);
  transform: rotateY(180deg) scale(0.5, 0.5);

  &:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: rotateY(0deg) scale(1, 1);
    -moz-transform: rotateY(0deg) scale(1, 1);
    -ms-transform: rotateY(0deg) scale(1, 1);
    -o-transform: rotateY(0deg) scale(1, 1);
    transform: rotateY(0deg) scale(1, 1); 
  }
`;

const ProjectTitle = styled.h2``;

export default ({
  // eslint-disable-next-line react/prop-types
  title, date, descriptions, technologies, ...rest
}) => {
  const technologyUsed = (technologies && technologies.length > 0)
    ? `Technologies: ${technologies.filter((t) => t !== 'Highlights').join(', ')}` : '';

  return (
    <ProjectItem
      xs="12"
      sm="6"
      md="4"
      lg="3"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      // className="project-item wow fadeInLeft overlay-item vertical-align"
    >
      <ProjectTitle>{ title }</ProjectTitle>
      <Year><Moment format="YYYY-MM-DD" interval={0}>{ date }</Moment></Year>
      {/* className="project-body overlay-body vertical-align" */}
      <HoverOverlay>
        { descriptions.map((line) => <p key={line.length}>{line}</p>) }
        <p>{ technologyUsed }</p>
      </HoverOverlay>
    </ProjectItem>
  );
};
