/* eslint-disable */
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBRow } from 'mdbreact';
import moment from 'moment';
import Moment from 'react-moment';
import React from 'react';
import styled from 'styled-components';
import { Container, Tab, Tabs } from 'react-bootstrap';
import YoutubeVideo from './YoutubeVideo';
import ListeningTo from './ListeningTo';
import { iftttDateOrUndefined } from './helpers.js';
import DeviceConnection from './DeviceConnection';
import DeviceBattery from './DeviceBattery';
import useFirebaseData from '../../utils/useFirebaseData';

const Timestamp = styled.section`
  color: darkgray;
  font-size: small;
  font-style: italic;
`;

const ActiveInfo = ({ activeInfo = useFirebaseData('public/activeInfo') }) => {
  const devices = _.mapValues(activeInfo.devices, recalculateLastTimestamp);
  return (
    <MDBRow>
      <Tabs
        defaultActiveKey={deviceNameWithRecentTimestamp(devices)}
        id="active-info-tabs"
      >
        {_.keys(devices).map((deviceName, index) => {
          const device = devices[deviceName];
          const {
            connection,
            isCharging,
            lastKnownBattery,
            lastTimestamp,
            listeningTo,
            myChevrolet,
          } = device;
          const likedYoutubeVideoUrl = _.at(device, 'videoLike.url')[0];

          return (
            <Tab eventKey={deviceName} key={deviceName} title={tabTitle(deviceName, device)}>
              <Container>
                {connection && <DeviceConnection {...connection} />}
                {lastKnownBattery && (
                  <DeviceBattery {...{ lastKnownBattery, isCharging }} />
                )}
                {likedYoutubeVideoUrl && (
                  <YoutubeVideo url={likedYoutubeVideoUrl} />
                )}
                {listeningTo && <ListeningTo {...listeningTo} />}
                {myChevrolet && <pre>{JSON.stringify(myChevrolet, 2, 2)}</pre>}
                {lastTimestamp && (
                  <Timestamp>
                    Last updated:
                    {lastTimestamp.toString()}
                  </Timestamp>
                )}
              </Container>
            </Tab>
          );
        })}
      </Tabs>
    </MDBRow>
  );
};

/* Helpers */
const recalculateLastTimestamp = (device) => {
  const lastTimestamp = iftttDateOrUndefined(_.get(device, 'listeningTo.timestamp'))
    || iftttDateOrUndefined(_.get(device, 'videoLike.timestamp'))
    || moment(device.lastTimestamp);
  return {
    ...device,
    lastTimestamp,
  };
};

const deviceNameWithRecentTimestamp = (devices) => {
  const recentTimestamp = _.chain(devices)
    .values()
    .map('lastTimestamp')
    .max()
    .value();
  return _.findKey(devices, { lastTimestamp: recentTimestamp });
};

const tabTitle = (deviceName, device) => (
  <>
    {deviceName}
    <Timestamp>
      <FontAwesomeIcon icon={['far', 'clock']} />
      {' '}
      <Moment date={device.lastTimestamp} fromNow />
    </Timestamp>
  </>
);

/* Exports */
export default ActiveInfo;
