import './styles.css';
import 'animate.css/animate.css';
import React from 'react';
import {
  MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBMask,
} from
  'mdbreact';
import useWow from './useWow';

export default () => {
  useWow();

  return (
    <MDBCarousel
      activeItem={1}
      length={3}
      showControls
      showIndicators
      className="z-depth-1"
    >
      <MDBCarouselInner>
        <MDBCarouselItem itemId="1">
          <img
            className="d-block w-100"
            src="https://drscdn.500px.org/photo/195752301/m%3D900/9431b71b2332ff4d60f78655e6e5865a"
            alt="First slide"
          />
          <MDBMask overlay="black-light" />
          <MDBCarouselCaption>
            <h2 className="wow bounceInDown more-weight">
              Meet
              <span className="larger color"> Ameya Naik</span>
              !
            </h2>
            <p className="wow bounceInDown" data-wow-duration="1500ms">passionated developer</p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="2">
          <img
            className="d-block w-100"
            src="https://drscdn.500px.org/photo/195757199/m%3D900/69d3b71db95b29d74a417b8becf211c4"
            alt="Second slide"
          />
          <MDBMask overlay="black-strong" />
          <MDBCarouselCaption>
            <h3 className="more-weight">Masters in Computer Science</h3>
            <h2 className="color">@ Stony Brook University</h2>
            <h3 className="more-weight">Bachelor in Computer Enginnering</h3>
            <p>@ University of Mumbai</p>
          </MDBCarouselCaption>
        </MDBCarouselItem>
        <MDBCarouselItem itemId="3">
          <img
            className="d-block w-100"
            src="https://drscdn.500px.org/photo/195755893/m%3D900/773da5aca1e66627bd30c81e9ba4e338"
            alt="Third slide"
          />
          <MDBMask overlay="black-slight" />
          <MDBCarouselCaption>
            <h2 className="more-weight">
              Software Engineer
              <span className="larger color"> @ Twitter</span>
            </h2>
          </MDBCarouselCaption>
        </MDBCarouselItem>
      </MDBCarouselInner>
    </MDBCarousel>
  );
};
