import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ContactInfoItem, Title } from './styled';

const contactInfos = [
  {
    icon: ['far', 'envelope'],
    label: 'ameya.n2007@gmail.com',
    linkAttr: {
      href: 'mailto:ameya.n2007@gmail.com',
      title: 'Mail: Ameya Naik',
    },
  },
  {
    icon: ['fab', 'google-plus-square'],
    label: 'Google+',
    linkAttr: {
      href: 'https://plus.google.com/+AmeyaNaik11/',
      title: 'Ameya Naik on Google+',
    },
  },
  {
    icon: ['fab', 'facebook-square'],
    label: 'Facebook',
    linkAttr: {
      href: 'https://www.facebook.com/ameyanaik11',
      title: 'Ameya Naik on Facebook',
    },
  },
  {
    icon: ['fab', 'linkedin'],
    label: 'LinkedIn',
    linkAttr: {
      href: 'http://in.linkedin.com/pub/ameya-naik/64/b36/16/',
      title: 'Ameya Naik on LinkedIn',
    },
  },
];

export default () => (
  <>
    <Title>Reach out to me</Title>
    {contactInfos.map((s) => (
      <ContactInfoItem key={s.label}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <a {...s.linkAttr}>
          <FontAwesomeIcon icon={s.icon} size="lg" />
          <span className="text">{ s.label }</span>
        </a>
      </ContactInfoItem>
    ))}
  </>
);
