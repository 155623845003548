import React, { useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { CustomFormGroup, ErrorText, Title } from './styled';

export default () => {
  const [errorText, setErrorText] = useState('');
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isInvalid, setIsInvalid] = useState({});
  const [isSent, setIsSent] = useState(false);
  const [sentStatus, setSentStatus] = useState(null);

  function handleFormSubmit(e) {
    e.preventDefault();
    const { name, email, message } = formData;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name.length < 3) {
      setIsInvalid({ name: true });
      setErrorText((name.length === 0)
        ? "Come on, why don't you mention name? "
        : 'It seems to be too short.. :P');
    } else if (email.length < 3) {
      setIsInvalid({ email: true });
      setErrorText('No email, no message. :/');
    } else if (!emailRegex.test(email)) {
      setIsInvalid({ email: true });
      setErrorText('Hmm, is that a valid email address? :P');
    } else if (message.length < 10) {
      setIsInvalid({ message: true });
      setErrorText((message.length === 0)
        ? 'Hmm, you have to write something.. :D'
        : 'thats all you have for me? really?');
    } else {
      setIsInvalid({});
      setErrorText('');

      try {
        // eslint-disable-next-line no-undef
        fetch('https://us-central1-ameya-naik-com.cloudfunctions.net/comment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(formData),
        }).then((resp) => resp.json())
          .then((resp) => {
            if (resp.success) {
              setIsSent(false);
              setSentStatus(resp.info);
            } else {
              setErrorText(resp.message);
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Send message failed', { name, email, message }, err);
        setErrorText('Something went wrong! o.O');
      }
    }
  }

  function handleInputChange(event) {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  }

  return (
    <>
      <Title>{ isSent ? 'Thanks for reaching out to me! :)' : 'Say Hello!' }</Title>
      <Form onSubmit={handleFormSubmit}>
        <CustomFormGroup controlId="formInlineNameEmail">
          <FormControl
            disabled={isSent}
            isInvalid={isInvalid.name}
            name="name"
            onChange={handleInputChange}
            placeholder="Your Name"
            type="text"
            value={formData.name}
          />
          <FormControl
            disabled={isSent}
            isInvalid={isInvalid.email}
            name="email"
            onChange={handleInputChange}
            placeholder="Email"
            type="text"
            value={formData.email}
          />
        </CustomFormGroup>
        <CustomFormGroup controlId="formMessage">
          <FormControl
            as="textarea"
            disabled={isSent}
            isInvalid={isInvalid.message}
            name="message"
            onChange={handleInputChange}
            placeholder="Message to Ameya"
            value={formData.message}
          />
        </CustomFormGroup>
        <Button className="submit-button" type="submit" disabled={isSent}>
          Send message
        </Button>
        {' '}
        <ErrorText>{ errorText }</ErrorText>
        {sentStatus}
      </Form>
    </>
  );
};
