import React from 'react';
import { Container, Row } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
const DeviceBattery = ({ lastKnownBattery, isCharging }) => {
  const status = isCharging === true ? 'Charging' : 'Unplugged';
  return (
    <Container>
      <Row>
        {`Battery: ${lastKnownBattery} (${status})`}
      </Row>
    </Container>
  );
};

export default DeviceBattery;
