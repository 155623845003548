import React from 'react';
import { Container, Row } from 'react-bootstrap';

// eslint-disable-next-line react/prop-types
const DeviceConnection = ({ isWifi, network }) => {
  const readableNetwork = network
    ? `${network} ${isWifi === true && '(WiFi)'}`
    : 'Mobile Data';

  return (
    <Container>
      <Row>
        Connection:
        {readableNetwork}
      </Row>
    </Container>
  );
};

export default DeviceConnection;
