import { Container } from 'react-bootstrap';
import React from 'react';
import styled from 'styled-components';
import YouTube from 'react-youtube';

const StyledContainer = styled(Container)`
  border: dotted 1px;
  text-align: center;
`;

/* Helper */
const getYoutubeVideoId = (url) => {
  // https://gist.github.com/takien/4077195
  const sanitizedUrl = url
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (sanitizedUrl[2] !== undefined) {
    return url[2].split(/[^0-9a-z_-]/i)[0];
  }
  return sanitizedUrl;
};

// eslint-disable-next-line react/prop-types
const YoutubeVideo = ({ url }) => (
  <StyledContainer>
    <YouTube videoId={getYoutubeVideoId(url)} />
  </StyledContainer>
);

export default YoutubeVideo;
