import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// eslint-disable-next-line react/prop-types
export default ({ children }) => (
  <p className="year">
    <small className="text-muted">
      <FontAwesomeIcon icon={['far', 'clock']} />
      {' '}
      {children}
    </small>
  </p>
);
