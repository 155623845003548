import { MDBCol } from 'mdbreact';
import styled from 'styled-components';

export const Background = styled.div`
  background-color: rgba(180, 14, 14, 0.78);
`;

export const IconWrapper = styled.span`
  border: 3px solid #0eb493;
  border-radius: 50%;
  display: inline-block;
  padding: 20px;
`;

export const ItemLabel = styled.p`
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
`;

export const ItemValue = styled.p`
  font-size: 45px;
`;

export const StatItem = styled(MDBCol)`
  color: white;
  text-align: center;
  margin: 15px 0;

  a {
    color: white;
  }

  a:hover {
    text-decoration: none;
  }
`;
