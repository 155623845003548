import styled from 'styled-components';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import React from 'react';

const CategoryCol = styled(MDBCol)`
  line-height: 21px;
  font-size: 14px;
  color: #6a737b;
`;

const TechnologyLi = styled.li`
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  /* #5b646e; */
  font-size: 14px;
  font-weight: 600;
  /*margin: 8px;*/
  padding: 5px 5px;

  background-color: ${(props) => props.current && '#0eb493'};
  border-radius: ${(props) => props.current && '#6px'};
  color: ${(props) => (props.current ? '#fff' : '#000000')};

  display: inline-block;  
`;

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

// eslint-disable-next-line react/prop-types
export default ({ active, onChange, projectGroups }) => {
  function handleCategoryChange(e) {
    const { filter } = e.currentTarget.dataset;
    onChange(filter);
  }

  return (
    <MDBContainer>
      { Object.keys(projectGroups).map((category) => (
        <MDBRow className="menu" key={category}>
          <CategoryCol xs="12" sm="3">{category}</CategoryCol>
          <MDBCol xs="12" sm="9">
            <Ul>
              { projectGroups[category].map((technology) => (
                <TechnologyLi
                  current={technology === active}
                  data-filter={technology}
                  key={technology}
                  onClick={handleCategoryChange}
                >
                  {technology}
                </TechnologyLi>
              ))}
            </Ul>
          </MDBCol>
        </MDBRow>
      ))}
    </MDBContainer>
  );
};
