import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { library } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import FirebaseProvider from './utils/firebase';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'development') {
  const release = `ameyanaik_com@${process.env.COMMIT_REF || 'missing'}`;

  // LogRocket
  LogRocket.init('latjcv/ameyanaik_com', { release });
  setupLogRocketReact(LogRocket);

  // Setup Sentry
  Sentry.init({
    dsn: 'https://f3261e61796f46b9b8c7d2ebdfc52c97@sentry.io/5176916',
    release,
  });

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

// Setup font-awesome library
library.add(fab, far, fas);

// eslint-disable-next-line no-undef
ReactDOM.render(<FirebaseProvider><App /></FirebaseProvider>, document.getElementById('root'));
