import { MDBRow } from 'mdbreact';
import React, { useState } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';
import ProjectItem from './ProjectItem';
import ProjectFilter from './ProjectFilter';

// eslint-disable-next-line react/prop-types
export default ({ projectGroups, projects }) => {
  const [selected, setSelection] = useState('Highlights');

  function handleSelection(selection) {
    setSelection(selection);
    // mixpanel.track('MyProjects#filter', { filter: selection });
  }

  const filteredProjects = projects
    .filter((p) => selected.includes('All')
      || p.technologies.includes(selected)
      || p.category.includes(selected));

  return (
    <>
      <MDBRow>
        <ProjectFilter projectGroups={projectGroups} onChange={handleSelection} active={selected} />
      </MDBRow>
      <MDBRow>
        <Flipper flipKey={selected}>
          { filteredProjects.map((p) => (
            <Flipped key={p.title} flipId={p.title}>
              {(flippedProps) => (
                <ProjectItem
                  date={p.date}
                  descriptions={p.descriptions}
                  technologies={p.technologies}
                  title={p.title}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...flippedProps}
                />
              )}
            </Flipped>
          )) }
        </Flipper>
      </MDBRow>
    </>
  );
};
