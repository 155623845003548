import React from 'react';
import { MDBCol, MDBRow } from 'mdbreact';
import ContactMeForm from './ContactMeForm';
import ContactInfoLinks from './ContactInfoLinks';
import Info from '../core/Info';

export default () => (
  <>
    <MDBRow>
      <MDBCol xs="12">
        <Info>
          I would be more than happy to hear from you.
          Feel free to reach out to me if you have any question,
          suggestion or feedback.
        </Info>
      </MDBCol>
    </MDBRow>
    <MDBRow>
      <MDBCol xs="12" sm="6" md="5" lg="4">
        { ContactInfoLinks() }
      </MDBCol>
      <MDBCol xs="12" sm="6" md="7" lg="8" className="contact-form">
        { ContactMeForm() }
      </MDBCol>
    </MDBRow>
  </>
);
