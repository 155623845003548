import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBRow } from 'mdbreact';
import React from 'react';
import { Container, DividerCol, TitleCol } from './styled';

const defaultIcon = ['fas', 'flask'];

// eslint-disable-next-line react/prop-types
export default ({ icon = defaultIcon, children, title }) => (
  <Container>
    <MDBRow>
      <TitleCol xs="12">{title}</TitleCol>
      <DividerCol xs="12">
        <FontAwesomeIcon icon={icon} />
      </DividerCol>
    </MDBRow>
    {children}
  </Container>
);
