import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MDBCol, MDBRow } from 'mdbreact';
import React from 'react';
import styled from 'styled-components';
import Year from '../core/Year';

const Smaller = styled.p`
  font-size: smaller;
`;

const TwitterLink = styled.a`
  color: #00aced;

  &:hover {
    color: #00aced;
  }
`;

// eslint-disable-next-line react/prop-types
export default () => (
  <MDBRow>
    <MDBCol xs="12" md="4" className="text-center">
      <Year>2013-14</Year>
      <p>SUNY Stony Brook</p>
      <Smaller>
        <FontAwesomeIcon icon={['fas', 'graduation-cap']} />
        {' '}
        Masters in Computer Science
      </Smaller>
      <Smaller>(GPA: 3.73)</Smaller>
    </MDBCol>
    <MDBCol xs="12" md="4" className="text-center">
      <Year>2015-16</Year>
      <p>CA Technologies Inc</p>
      <Smaller>
        <FontAwesomeIcon icon={['fas', 'trophy']} />
        {' '}
        Winner of CA Global Hackathon
      </Smaller>
    </MDBCol>
    <MDBCol xs="12" md="4" className="text-center">
      <Year>since 2016</Year>
      <p>
        <TwitterLink href="https://twitter.com/ameyanaik11/">
          <FontAwesomeIcon icon={['fab', 'twitter']} size="4x" />
          <br />
          #ameyanaik11
        </TwitterLink>
      </p>
    </MDBCol>
  </MDBRow>
);
